import { Cell, CellFilter, CellIntrospect, PageResult, MigrationCellFilter } from '../../models';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export class CellApiService {
  static ApiService: CCApiService;
  static cellApi: string;
  static introspectApi: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.cellApi = `${config.apiBasePath}/cells`;
    this.introspectApi = `${config.apiBasePath}/introspection/cells`;
  }

  static getCells(filter?: CellFilter): Promise<PageResult<Cell>> {
    const params = new URLSearchParams(filter);
    const url = params.toString() ? `${this.cellApi}?${params}` : this.cellApi;
    return this.ApiService.performFetch(url).then(cells => cells);
  }

  static getMigrationCells(filter?: MigrationCellFilter): Promise<PageResult<Cell>> {
    const params = new URLSearchParams(filter);
    const url = params.toString() ? `${this.cellApi}/applications/migrations?${params}` : this.cellApi;
    return this.ApiService.performFetch(url).then(cells => cells);
  }

  static getCell(name: string): Promise<Cell> {
    return this.ApiService.performFetch(`${this.cellApi}/${name}`);
  }

  static createCell(cell: Cell): Promise<Cell> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(cell),
    };
    return this.ApiService.performFetch(this.cellApi, fetchOptions);
  }

  static updateCell(name: string, cell: Cell) {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(cell),
    };
    return this.ApiService.performFetch(`${this.cellApi}/${name}`, fetchOptions);
  }

  static deleteCell(name: string) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.cellApi}/${name}`, fetchOptions);
  }

  static introspect(): Promise<CellIntrospect> {
    return this.ApiService.performFetch(this.introspectApi);
  }
}
